.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pointer {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* body { */
/*   color: rgba(64, 64, 64, 0.65); */
/*   font: 500 1em Helvetica, sans-serif; */
/*   -webkit-font-smoothing: antialiased; */
/*   text-rendering: optimizelegibility; */
/*   margin: 0; */
/*   padding: 0; */
/* } */
/**/
/* *:focus { */
/*   outline: none !important; */
/* } */
/**/
/* *, */
/* ::after, */
/* ::before { */
/*   box-sizing: border-box; */
/* } */
/**/


.Dropzone-input-wrapper {
  width: 100%;
  max-width: 800px;
}

.Dropzone-input {
  background-color: white;
  line-height: normal;
  align-self: center;
  border-radius: 2px;
  margin: 40px 0;
  border: none;
  height: 52px;
  width: 100%;
  font-size: 1.1em;
  font-family: normal 1em Helvetica, sans-serif;
  padding: 0px 22px;
  color: rgb(64, 64, 64);
  box-shadow: rgba(0, 0, 0, 0.14902) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.0784314) 0px 0px 0px 1px;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.Dropzone-input:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 9px 0px,
    rgba(0, 0, 0, 0.0784314) 0px 0px 0px 1px;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.Dropzone-img {
  margin: 5px;
  width: 100px;
  height: 100px;
  border: thin solid rgba(64, 64, 64, 0.15);
  border-radius: 5px;
  object-fit: cover;
}

.Dropzone {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: thin dashed #c2c2c2;
  background: #fcfcfc;
  min-width: 250px;
  max-width: 800px;
  min-height: 120px;
  padding: 16px 11px;
  border-radius: 5px;
}

.Dropzone-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.Dropzone-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}



.edit-image-button {
  /* position: absolute; */
  right: 0px;
}

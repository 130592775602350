.legend {
  display: flex;
  align-items: center;
}

.legend-item {
  width: 30px;
  height: 20px;
  margin-right: 5px;
  border: 2px solid #000;
}

.red {
  background-color: #f44336;
}

.yellow {
  background-color: #efbb5aa3;
}

.green {
  background-color: #088208a3;
}

.legend-label {
  margin-right: 10px;
  text-decoration: underline;
  font-weight: bold;
  font-size: 12px;
}

table {
    width: 800px;
    height: 200px;
  }

  th {
    border-bottom: 1px solid black;
  }

  td {
    text-align: left;
  }
